import "./App.css";
import "./index.css";

function App() {
  return (
    <div className="grid justify-items-center h-screen font-sans dark:bg-black dark:text-white">
      <div className="mt-32 mx-10">
        <header className="font-semibold mb-10">
          <h1>Pythagorean Engineering</h1>
        </header>
        <main className="font-normal leading-8 font-sans">
          <p>
            Pythagorean Engineering is a software development team based in Los
            Angeles, CA.
          </p>

          <div className="grid justify-items-end mt-10">
            <div>
              <a href="https://github.com/pythagoreaneng" className="underline">
                GitHub
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
